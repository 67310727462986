import {
  createEventsTable,
  createProjectionsTable,
} from "@status-machina/drizzle-pg-pattern";
import { Message } from "ai";
import { eq, sql } from "drizzle-orm";
import {
  AnyPgColumn,
  boolean,
  index,
  integer,
  json,
  jsonb,
  pgEnum,
  pgSchema,
  pgTable,
  pgView,
  text,
  timestamp,
  uniqueIndex,
  uuid,
} from "drizzle-orm/pg-core";
import { relations } from "drizzle-orm/relations";
import { QuestionContent, ResponseContent, Tuuid } from "./jsonTypes";

export const assignmentState = pgEnum("assignment_state", [
  "draft",
  "scheduled",
  "active",
]);

export const questionState = pgEnum("question_state", ["active", "deleted"]);

export const questionFlagStatus = pgEnum("question_flag_status", [
  "use",
  "edit",
  "drop",
]);

const authSchema = pgSchema("auth");

export const auth = {
  users: authSchema.table("users", {
    id: uuid("id").$type<Tuuid>().primaryKey(),
    email: text("email").notNull(),
    raw_user_meta_data: json("raw_user_meta_data").notNull(),
  }),
};

export const teacherProfiles = pgTable("teacher_profiles", {
  id: uuid("id")
    .$type<Tuuid>()
    .notNull()
    .primaryKey()
    .references(() => auth.users.id, { onDelete: "cascade" }),
  firstName: text("first_name"),
  lastName: text("last_name"),
  email: text("email").notNull(),
  isAdmin: boolean("is_admin").notNull().default(false),
  isReviewer: boolean("is_reviewer").notNull().default(false),
  defaultCourseId: integer("default_course_id").references(() => courses.id, {
    onDelete: "set null",
  }),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
    () => new Date(),
  ),
});

export const teacherCourses = pgTable(
  "teacher_courses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    teacherProfileId: uuid("teacher_profile_id")
      .$type<Tuuid>()
      .notNull()
      .references(() => teacherProfiles.id, { onDelete: "cascade" }),
    courseId: integer("course_id")
      .notNull()
      .references(() => courses.id, { onDelete: "cascade" }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("teacher_course_idx").on(
      table.teacherProfileId,
      table.courseId,
    ),
    index("teacher_courses_teacher_id_idx").on(table.teacherProfileId),
    index("teacher_courses_course_id_idx").on(table.courseId),
  ],
);

export const teacherCoursesRelations = relations(teacherCourses, ({ one }) => ({
  teacher: one(teacherProfiles, {
    fields: [teacherCourses.teacherProfileId],
    references: [teacherProfiles.id],
  }),
  course: one(courses, {
    fields: [teacherCourses.courseId],
    references: [courses.id],
  }),
}));

export const teacherProfilesRelations = relations(
  teacherProfiles,
  ({ many }) => ({
    questionFlags: many(questionFlags),
    teacherCourses: many(teacherCourses),
  }),
);

export const studentProfiles = pgTable("student_profiles", {
  id: uuid("id")
    .$type<Tuuid>()
    .notNull()
    .primaryKey()
    .references(() => auth.users.id, { onDelete: "cascade" }),
  firstName: text("first_name"),
  lastName: text("last_name"),
  email: text("email").notNull(),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
    () => new Date(),
  ),
});

export const courses = pgTable(
  "courses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    name: text("name").notNull(),
    courseCode: text("course_code")
      .notNull()
      .default(sql`upper(substr(md5(random()::text), 1, 4))`),
    description: text("description"),
    private: boolean("private").notNull().default(true),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [uniqueIndex("course_code_idx").on(table.courseCode)],
);

export const standards = pgTable(
  "standards",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    courseId: integer("course_id")
      .notNull()
      .references(() => courses.id, { onDelete: "cascade" }),
    parentId: integer("parent_id").references((): AnyPgColumn => standards.id, {
      onDelete: "set null",
    }),
    name: text("name").notNull(),
    description: text("description").notNull(),
    metadata: jsonb("metadata").default({}),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("standards_course_id_idx").on(table.courseId),
    index("standards_parent_id_idx").on(table.parentId),
  ],
);

export const groups = pgTable(
  "groups",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    groupName: text("group_name").notNull(),
    groupCode: text("group_code").notNull(),
    weeklyGoal: integer("weekly_goal").notNull().default(2),
    courseId: integer("course_id")
      .notNull()
      .references(() => courses.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("group_code_idx").on(table.groupCode),
    index("groups_course_id_idx").on(table.courseId),
  ],
);

export const teacherGroups = pgTable(
  "teacher_groups",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    teacherProfileId: uuid("teacher_profile_id")
      .$type<Tuuid>()
      .notNull()
      .references(() => teacherProfiles.id),
    groupId: integer("group_id")
      .notNull()
      .references(() => groups.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("teacher_profile_id_group_id_idx").on(
      table.teacherProfileId,
      table.groupId,
    ),
    index("teacher_profile_id_idx").on(table.teacherProfileId),
    index("teacher_groups_group_id_idx").on(table.groupId),
  ],
);

export const enrollments = pgTable(
  "enrollments",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    studentProfileId: uuid("student_profile_id")
      .$type<Tuuid>()
      .notNull()
      .references(() => studentProfiles.id),
    groupId: integer("group_id")
      .notNull()
      .references(() => groups.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("student_profile_id_group_id_idx").on(
      table.studentProfileId,
      table.groupId,
    ),
    index("student_profile_id_idx").on(table.studentProfileId),
    index("enrollments_group_id_idx").on(table.groupId),
  ],
);

export const assignments = pgTable(
  "assignments",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    title: text("title").notNull(),
    description: text("description"),
    createdBy: uuid("created_by")
      .$type<Tuuid>()
      .notNull()
      .references(() => teacherProfiles.id),
    state: assignmentState("state").default("draft").notNull(),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [index("created_by_idx").on(table.createdBy)],
);

export const courseAssignments = pgTable(
  "course_assignments",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    courseId: integer("course_id")
      .notNull()
      .references(() => courses.id, { onDelete: "cascade" }),
    assignmentId: integer("assignment_id")
      .notNull()
      .references(() => assignments.id, { onDelete: "cascade" }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("course_assignment_idx").on(table.courseId, table.assignmentId),
    index("course_assignments_course_id_idx").on(table.courseId),
    index("course_assignments_assignment_id_idx").on(table.assignmentId),
  ],
);

export const assignmentsRelations = relations(assignments, ({ many, one }) => ({
  assignmentQuestions: many(assignmentQuestions),
  assignedAssignments: many(assignedAssignments),
  courseAssignments: many(courseAssignments),
  createdByTeacher: one(teacherProfiles, {
    fields: [assignments.createdBy],
    references: [teacherProfiles.id],
  }),
}));

export const assignedAssignments = pgTable(
  "assigned_assignments",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    assignmentId: integer("assignment_id")
      .notNull()
      .references(() => assignments.id),
    groupId: integer("group_id").references(() => groups.id),
    enrollmentId: integer("enrollment_id").references(() => enrollments.id),
    dueDate: timestamp("due_date", { withTimezone: true }),
    launchDate: timestamp("launch_date", { withTimezone: true }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("assignment_id_group_id_idx").on(
      table.assignmentId,
      table.groupId,
    ),
    index("assignment_id_idx").on(table.assignmentId),
    index("assigned_group_id_idx").on(table.groupId),
  ],
);

export const questions = pgTable(
  "questions",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    questionContent: jsonb("question_content")
      .$type<QuestionContent>()
      .notNull(),
    createdBy: uuid("created_by")
      .$type<Tuuid>()
      .notNull()
      .references(() => auth.users.id),
    state: questionState("state").default("active").notNull(),
    knowledgeComponentId: integer("knowledge_component_id").references(
      () => knowledgeComponents.id,
    ),
    aiGenerated: boolean("ai_generated").notNull().default(false),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("questions_created_by_idx").on(table.createdBy),
    index("questions_knowledge_component_idx").on(table.knowledgeComponentId),
  ],
);

export const questionsRelations = relations(questions, ({ one, many }) => ({
  assignmentQuestions: many(assignmentQuestions),
  knowledgeComponent: one(knowledgeComponents, {
    fields: [questions.knowledgeComponentId],
    references: [knowledgeComponents.id],
  }),
  questionResources: many(questionResources),
  questionFlags: many(questionFlags),
  coreStandard: one(standardCoreQuestions, {
    fields: [questions.id],
    references: [standardCoreQuestions.questionId],
  }),
}));

export const knowledgeComponents = pgTable(
  "knowledge_components",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    originalQuestionId: integer("original_question_id").notNull(),
    standardId: integer("standard_id").references(() => standards.id, {
      onDelete: "set null",
    }),
    name: text("name").notNull(),
    description: text("description"),
    public: boolean("public").notNull().default(true),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("knowledge_components_standard_id_idx").on(table.standardId),
    index("knowledge_components_original_question_id_idx").on(
      table.originalQuestionId,
    ),
  ],
);

export const knowledgeComponentsRelations = relations(
  knowledgeComponents,
  ({ many, one }) => ({
    questions: many(questions),
    knowledgeComponentProgress: many(knowledgeComponentProgress),
    studySessionKnowledgeComponents: many(studySessionKnowledgeComponents),
    originalQuestion: one(questions, {
      fields: [knowledgeComponents.originalQuestionId],
      references: [questions.id],
    }),
    standard: one(standards, {
      fields: [knowledgeComponents.standardId],
      references: [standards.id],
    }),
  }),
);

export const assignmentQuestions = pgTable(
  "assignment_questions",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    assignmentId: integer("assignment_id")
      .notNull()
      .references(() => assignments.id),
    questionId: integer("question_id")
      .notNull()
      .references(() => questions.id),
    order: integer("order").notNull(),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("assignment_questions_assignment_id_idx").on(table.assignmentId),
    index("assignment_questions_question_id_idx").on(table.questionId),
  ],
);

export const assignmentQuestionsRelations = relations(
  assignmentQuestions,
  ({ one }) => ({
    assignment: one(assignments, {
      fields: [assignmentQuestions.assignmentId],
      references: [assignments.id],
    }),
    question: one(questions, {
      fields: [assignmentQuestions.questionId],
      references: [questions.id],
    }),
  }),
);

export const assignedAssignmentsRelations = relations(
  assignedAssignments,
  ({ one }) => ({
    assignment: one(assignments, {
      fields: [assignedAssignments.assignmentId],
      references: [assignments.id],
    }),
  }),
);

export const enrollmentsRelations = relations(enrollments, ({ one, many }) => ({
  studentProfile: one(studentProfiles, {
    fields: [enrollments.studentProfileId],
    references: [studentProfiles.id],
  }),
  group: one(groups, {
    fields: [enrollments.groupId],
    references: [groups.id],
  }),
  knowledgeComponentProgress: many(knowledgeComponentProgress),
}));

export const groupsRelations = relations(groups, ({ many, one }) => ({
  enrollments: many(enrollments),
  teacherGroups: many(teacherGroups),
  course: one(courses, { fields: [groups.courseId], references: [courses.id] }),
}));

export const responses = pgTable(
  "responses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    enrollmentId: integer("enrollment_id")
      .notNull()
      .references(() => enrollments.id),
    questionId: integer("question_id")
      .notNull()
      .references(() => questions.id),
    responseContent: jsonb("response_content")
      .$type<ResponseContent>()
      .notNull(),
    isCorrect: boolean("is_correct"),
    firstAttemptOfSession: boolean("first_attempt_of_session")
      .notNull()
      .default(true),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("responses_enrollment_id_idx").on(table.enrollmentId),
    index("responses_question_id_idx").on(table.questionId),
  ],
);

export const assignmentQuestionResponses = pgTable(
  "assignment_question_responses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    assignmentQuestionId: integer("assignment_question_id")
      .notNull()
      .references(() => assignmentQuestions.id),
    responseId: integer("response_id")
      .notNull()
      .references(() => responses.id),
    assignedAssignmentId: integer("assigned_assignment_id")
      .notNull()
      .references(() => assignedAssignments.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("assignment_question_response_idx").on(
      table.assignmentQuestionId,
      table.responseId,
    ),
  ],
);

export const assignmentQuestionProgress = pgView(
  "assignment_question_progress",
).as((qb) => {
  return qb
    .select({
      assignmentQuestionId: assignmentQuestions.id,
      assignmentId: assignmentQuestions.assignmentId,
      assignedAssignmentId: assignmentQuestionResponses.assignedAssignmentId,
      enrollmentId: responses.enrollmentId,
      questionId: assignmentQuestions.questionId,
      attemptCount: sql<number>`count(${responses.id})`.as("attempt_count"),
      hasCorrectAnswer: sql<boolean>`bool_or(${responses.isCorrect})`.as(
        "has_correct_answer",
      ),
      firstAttemptCorrect: sql<boolean>`bool_and(CASE 
          WHEN ${responses.firstAttemptOfSession} THEN ${responses.isCorrect}
          ELSE true 
        END)`.as("first_attempt_correct"),
    })
    .from(assignmentQuestions)
    .leftJoin(
      assignmentQuestionResponses,
      eq(
        assignmentQuestionResponses.assignmentQuestionId,
        assignmentQuestions.id,
      ),
    )
    .leftJoin(
      responses,
      eq(responses.id, assignmentQuestionResponses.responseId),
    )
    .groupBy(
      assignmentQuestions.id,
      assignmentQuestions.assignmentId,
      assignmentQuestionResponses.assignedAssignmentId,
      responses.enrollmentId,
      assignmentQuestions.questionId,
    );
});

export const knowledgeComponentProgress = pgTable(
  "knowledge_component_progress",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    enrollmentId: integer("enrollment_id")
      .notNull()
      .references(() => enrollments.id, { onDelete: "cascade" }),
    knowledgeComponentId: integer("knowledge_component_id")
      .notNull()
      .references(() => knowledgeComponents.id),
    attemptCount: integer("attempt_count").notNull().default(0),
    correctCount: integer("correct_count").notNull().default(0),
    firstAttemptOfSessionCount: integer("first_attempt_of_session_count")
      .notNull()
      .default(0),
    firstAttemptOfSessionCorrect: integer("first_attempt_of_session_correct")
      .notNull()
      .default(0),
    lastReviewedAt: timestamp("last_reviewed_at", { withTimezone: true }),
    algorithmData: jsonb("algorithm_data").default({
      fsrs: {
        state: "New",
        stability: 0,
        difficulty: 5,
        elapsed_days: 0,
        scheduled_days: 0,
        reps: 0,
        lapses: 0,
        due: null,
      },
    }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("enrollment_kc_unique_idx").on(
      table.enrollmentId,
      table.knowledgeComponentId,
    ),
    index("kc_progress_enrollment_idx").on(table.enrollmentId),
  ],
);

export const knowledgeComponentProgressRelations = relations(
  knowledgeComponentProgress,
  ({ one }) => ({
    enrollment: one(enrollments, {
      fields: [knowledgeComponentProgress.enrollmentId],
      references: [enrollments.id],
    }),
    knowledgeComponent: one(knowledgeComponents, {
      fields: [knowledgeComponentProgress.knowledgeComponentId],
      references: [knowledgeComponents.id],
    }),
  }),
);

export const responsesRelations = relations(responses, ({ one, many }) => ({
  enrollment: one(enrollments, {
    fields: [responses.enrollmentId],
    references: [enrollments.id],
  }),
  question: one(questions, {
    fields: [responses.questionId],
    references: [questions.id],
  }),
  assignmentQuestionResponses: many(assignmentQuestionResponses),
  studySessionResponses: many(studySessionResponses),
}));

export const assignmentQuestionResponsesRelations = relations(
  assignmentQuestionResponses,
  ({ one }) => ({
    assignmentQuestion: one(assignmentQuestions, {
      fields: [assignmentQuestionResponses.assignmentQuestionId],
      references: [assignmentQuestions.id],
    }),
    response: one(responses, {
      fields: [assignmentQuestionResponses.responseId],
      references: [responses.id],
    }),
    assignedAssignment: one(assignedAssignments, {
      fields: [assignmentQuestionResponses.assignedAssignmentId],
      references: [assignedAssignments.id],
    }),
  }),
);

export const studySessions = pgTable(
  "study_sessions",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    enrollmentId: integer("enrollment_id")
      .notNull()
      .references(() => enrollments.id, { onDelete: "cascade" }),
    completedAt: timestamp("completed_at", { withTimezone: true }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [index("study_sessions_enrollment_id_idx").on(table.enrollmentId)],
);

export const studySessionKnowledgeComponents = pgTable(
  "study_session_knowledge_components",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    studySessionId: integer("study_session_id")
      .notNull()
      .references(() => studySessions.id, { onDelete: "cascade" }),
    knowledgeComponentId: integer("knowledge_component_id")
      .notNull()
      .references(() => knowledgeComponents.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("study_session_kc_idx").on(
      table.studySessionId,
      table.knowledgeComponentId,
    ),
    index("study_session_kc_session_id_idx").on(table.studySessionId),
    index("study_session_kc_kc_id_idx").on(table.knowledgeComponentId),
  ],
);

export const studySessionResponses = pgTable(
  "study_session_responses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    studySessionId: integer("study_session_id")
      .notNull()
      .references(() => studySessions.id),
    responseId: integer("response_id")
      .notNull()
      .references(() => responses.id),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("study_session_response_idx").on(
      table.studySessionId,
      table.responseId,
    ),
  ],
);

export const studySessionsRelations = relations(
  studySessions,
  ({ one, many }) => ({
    enrollment: one(enrollments, {
      fields: [studySessions.enrollmentId],
      references: [enrollments.id],
    }),
    studySessionKnowledgeComponents: many(studySessionKnowledgeComponents),
    studySessionResponses: many(studySessionResponses),
  }),
);

export const studySessionKnowledgeComponentsRelations = relations(
  studySessionKnowledgeComponents,
  ({ one }) => ({
    studySession: one(studySessions, {
      fields: [studySessionKnowledgeComponents.studySessionId],
      references: [studySessions.id],
    }),
    knowledgeComponent: one(knowledgeComponents, {
      fields: [studySessionKnowledgeComponents.knowledgeComponentId],
      references: [knowledgeComponents.id],
    }),
  }),
);

export const studySessionResponsesRelations = relations(
  studySessionResponses,
  ({ one }) => ({
    studySession: one(studySessions, {
      fields: [studySessionResponses.studySessionId],
      references: [studySessions.id],
    }),
    response: one(responses, {
      fields: [studySessionResponses.responseId],
      references: [responses.id],
    }),
  }),
);

export const aiEvaluatedResponses = pgTable(
  "ai_evaluated_responses",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    questionId: integer("question_id")
      .notNull()
      .references(() => questions.id),
    answerText: text("evaluated_answer_text").notNull(),
    evaluationScore: integer("evaluation_score").notNull(),
    evaluationReasoning: text("evaluation_reasoning").notNull(),
    isAccepted: boolean("is_accepted").notNull(),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("ai_evaluated_responses_question_id_idx").on(table.questionId),
  ],
);

export const aiEvaluatedResponsesRelations = relations(
  aiEvaluatedResponses,
  ({ one }) => ({
    question: one(questions, {
      fields: [aiEvaluatedResponses.questionId],
      references: [questions.id],
    }),
  }),
);

export const coursesRelations = relations(courses, ({ many }) => ({
  standards: many(standards),
  groups: many(groups),
  courseAssignments: many(courseAssignments),
}));

export const standardsRelations = relations(standards, ({ one, many }) => ({
  course: one(courses, {
    fields: [standards.courseId],
    references: [courses.id],
  }),
  knowledgeComponents: many(knowledgeComponents),
  standardResources: many(standardResources),
  coreQuestion: one(standardCoreQuestions, {
    fields: [standards.id],
    references: [standardCoreQuestions.standardId],
  }),
  subStandards: many(standards, { relationName: "standardHierarchy" }),
  parentStandard: one(standards, {
    fields: [standards.parentId],
    references: [standards.id],
    relationName: "standardHierarchy",
  }),
}));

export const resources = pgTable("resources", {
  id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
  title: text("title").notNull(),
  type: text("type").notNull(),
  content: jsonb("content").notNull(),
  createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
  updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
    () => new Date(),
  ),
});

export const questionResources = pgTable(
  "question_resources",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    questionId: integer("question_id")
      .notNull()
      .references(() => questions.id, { onDelete: "cascade" }),
    resourceId: integer("resource_id")
      .notNull()
      .references(() => resources.id, { onDelete: "cascade" }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("question_resource_idx").on(table.questionId, table.resourceId),
    index("question_resources_question_id_idx").on(table.questionId),
    index("question_resources_resource_id_idx").on(table.resourceId),
  ],
);

export const resourcesRelations = relations(resources, ({ many }) => ({
  questionResources: many(questionResources),
  standardResources: many(standardResources),
}));

export const questionResourcesRelations = relations(
  questionResources,
  ({ one }) => ({
    question: one(questions, {
      fields: [questionResources.questionId],
      references: [questions.id],
    }),
    resource: one(resources, {
      fields: [questionResources.resourceId],
      references: [resources.id],
    }),
  }),
);

export const standardResources = pgTable(
  "standard_resources",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    standardId: integer("standard_id")
      .notNull()
      .references(() => standards.id, { onDelete: "cascade" }),
    resourceId: integer("resource_id")
      .notNull()
      .references(() => resources.id, { onDelete: "cascade" }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("standard_resource_idx").on(table.standardId, table.resourceId),
    index("standard_resources_standard_id_idx").on(table.standardId),
    index("standard_resources_resource_id_idx").on(table.resourceId),
  ],
);

export const standardResourcesRelations = relations(
  standardResources,
  ({ one }) => ({
    standard: one(standards, {
      fields: [standardResources.standardId],
      references: [standards.id],
    }),
    resource: one(resources, {
      fields: [standardResources.resourceId],
      references: [resources.id],
    }),
  }),
);

export const questionFlags = pgTable(
  "question_flags",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    questionId: integer("question_id").references(() => questions.id, {
      onDelete: "set null",
    }),
    teacherProfileId: uuid("teacher_profile_id")
      .$type<Tuuid>()
      .references(() => teacherProfiles.id, { onDelete: "cascade" }),
    status: questionFlagStatus("status").notNull(),
    notes: text("notes"),
    aiGenerated: boolean("ai_generated").notNull().default(false),
    metadata: jsonb("metadata").default({}),
    isResolved: boolean("is_resolved").notNull().default(false),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("question_flags_question_id_idx").on(table.questionId),
    index("question_flags_teacher_id_idx").on(table.teacherProfileId),
    uniqueIndex("question_teacher_flag_idx")
      .on(table.questionId, table.teacherProfileId)
      .where(
        sql`${table.teacherProfileId} IS NOT NULL AND ${table.questionId} IS NOT NULL`,
      ),
  ],
);

export const questionFlagsRelations = relations(questionFlags, ({ one }) => ({
  question: one(questions, {
    fields: [questionFlags.questionId],
    references: [questions.id],
  }),
  teacher: one(teacherProfiles, {
    fields: [questionFlags.teacherProfileId],
    references: [teacherProfiles.id],
  }),
}));

export const systemPrompts = pgTable(
  "system_prompts",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    name: text("name").notNull(),
    content: text("content").notNull(),
    category: text("category").notNull(),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [uniqueIndex("system_prompt_name_idx").on(table.name)],
);

// Keys added to dataIndexes are used to create a sparse index on that field within the `data` column of the events table
// This is used to speed up queries on the events table
export const events = createEventsTable({
  name: "events",
  dataIndexes: [
    "assignmentId",
    "questionAttemptId",
    "questionId",
    "responseId",
    "studentProfileId",
    "studySessionId",
    "groupId",
  ],
});

export const projections = createProjectionsTable({
  name: "projections",
  dataIndexes: [
    "assignmentId",
    "questionId",
    "responseId",
    "studentProfileId",
    "studySessionId",
    "experimentId",
  ],
});

export const courseAssignmentsRelations = relations(
  courseAssignments,
  ({ one }) => ({
    course: one(courses, {
      fields: [courseAssignments.courseId],
      references: [courses.id],
    }),
    assignment: one(assignments, {
      fields: [courseAssignments.assignmentId],
      references: [assignments.id],
    }),
  }),
);

export const standardCoreQuestions = pgTable(
  "standard_core_questions",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    standardId: integer("standard_id")
      .notNull()
      .references(() => standards.id, { onDelete: "cascade" }),
    questionId: integer("question_id")
      .notNull()
      .references(() => questions.id, { onDelete: "cascade" }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    uniqueIndex("standard_core_question_idx").on(table.standardId),
    index("standard_core_questions_standard_id_idx").on(table.standardId),
    index("standard_core_questions_question_id_idx").on(table.questionId),
  ],
);

export const standardCoreQuestionsRelations = relations(
  standardCoreQuestions,
  ({ one }) => ({
    standard: one(standards, {
      fields: [standardCoreQuestions.standardId],
      references: [standards.id],
    }),
    question: one(questions, {
      fields: [standardCoreQuestions.questionId],
      references: [questions.id],
    }),
  }),
);

export const chats = pgTable(
  "chats",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    teacherProfileId: uuid("teacher_profile_id")
      .$type<Tuuid>()
      .notNull()
      .references(() => teacherProfiles.id, {
        onDelete: "cascade",
      }),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [index("chats_teacher_profile_id_idx").on(table.teacherProfileId)],
);

export const messages = pgTable(
  "messages",
  {
    id: integer("id").primaryKey().generatedByDefaultAsIdentity(),
    chatId: integer("chat_id")
      .notNull()
      .references(() => chats.id, {
        onDelete: "cascade",
      }),
    message: jsonb("message").$type<Message>().notNull(),
    messageId: text("message_id").notNull(),
    createdAt: timestamp("created_at", { withTimezone: true }).defaultNow(),
    updatedAt: timestamp("updated_at", { withTimezone: true }).$onUpdateFn(
      () => new Date(),
    ),
  },
  (table) => [
    index("messages_chat_id_idx").on(table.chatId),
    uniqueIndex("messages_message_id_idx").on(table.messageId),
  ],
);

export const chatsRelations = relations(chats, ({ one, many }) => ({
  teacher: one(teacherProfiles, {
    fields: [chats.teacherProfileId],
    references: [teacherProfiles.id],
  }),
  messages: many(messages),
}));
